export default [
  {
    title: "Home",
    route: "home",
    icon: "HomeIcon",
  },
  {
    title: "Calendar",
    route: "apps-calendar",
    icon: "CalendarIcon",
  },
  {
    title: "Leads",
    route: "leads",
    icon: "PhoneCallIcon",
  },
  {
    title: "Deals",
    route: "deals",
    icon: "AwardIcon",
  },
  {
    title: "Shipments",
    route: "shipments",
    icon: "MapPinIcon",
  },
  {
    title: "Contacts",
    route: "contacts",
    icon: "BookIcon",
  },
  {
    title: "Marketing",
    icon: "ImageIcon",
    children: [
      {
        title: "Campaigns",
        route: "campaigns",
      },
      {
        title: "Channels",
        route: "channels",
      },
      {
        title: "Medium",
        route: "medium",
      },
    ],
  },
  {
    title: "Landing Page",
    icon: "LayoutIcon",
    children: [
      {
        title: "Templates",
        route: "landing-page-templates",
      },
      {
        title: "URLs",
        route: "landing-page-urls",
      },
      {
        title: "Domains",
        route: "landing-page-domains",
      },
      {
        title: "Conversion tracking",
        route: "landing-page-conversion-tracking",
      },
      {
        title: "Forms",
        route: "landing-page-forms",
      },
      {
        title: "Split testing",
        route: "landing-page-split-testing",
      },
    ],
  },
  {
    title: "Books",
    icon: "DatabaseIcon",
    children: [
      {
        title: "Orders",
        route: "books-orders",
      },
      {
        title: "Estimates",
        route: "books-estimates",
      },
      {
        title: "Credit Notes",
        route: "books-credit-notes",
      },
      {
        title: "Payments Received",
        route: "books-payments-received",
      },
      {
        title: "Expenses",
        route: "books-expenses",
      },
    ],
  },
  {
    title: "Tasks",
    route: "apps-todo",
    icon: "ListIcon",
  },
  {
    title: "Messages",
    route: "apps-email",
    icon: "MessageSquareIcon",
  },
  {
    title: "Activeties",
    route: "notes",
    icon: "FileTextIcon",
  },
  {
    title: "Users",
    icon: "UsersIcon",
    children: [
      {
        title: "List",
        route: "users-list",
      },
      {
        title: "Roles",
        route: "users-roles",
      },
      {
        title: "Permessions",
        route: "users-permessions",
      },
    ],
  },
  {
    title: "Reports",
    route: "reports",
    icon: "TrendingUpIcon",
  },
  {
    title: "Settings",
    route: "settings",
    icon: "SettingsIcon",
  },
  // {
  //   title: "Projects",
  //   route: "projects",
  //   icon: "ClockIcon",
  // },
];
